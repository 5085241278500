<template>
    <div :key="refreshkey" id="billflow-embed" ></div>
</template>

<script>
export default {
  name: 'portal',
  data() {
    return {
      refreshkey: 0,
      settings: {
        billing_page_id: '9LXYFuwMmGj7wiwh0Wi3',
        options: {
          default_tax_rates: ['txr_1FaXJaJg0iXaYOVYUGLsPvtW'],
        },
        email: this.$auth.user.email,
        hash: this.$auth.user['https://api.pharmaguide.ca/claims/app_metadata'].hmac,
      },
    }
  },
  watch: {
    settings: function (updatedSettings) {
      window.billflow.destroy()
      window.billflow.init({
        ...updatedSettings,
      })
    },
  },
  methods: {
    getUserInfo() {
      return {
        email: this.$auth.user.email,
        hash: this.$auth.user['https://api.pharmaguide.ca/claims/app_metadata'].hmac,
      }
    },

    getBillflow: function () {
      this.getUserInfo()
      var s = document.createElement('script')
      s.src = 'https://js.billflow.io/beta/billflow-embed.js'
      s.async = true
      s.type = 'text/javascript'
      var x = document.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
      this.refreshkey += 1
    },
  },
  mounted() {
    this.getUserInfo()
    window.billflowSettings = this.settings
    this.refreshkey += 1

    this.getBillflow()
  },
}
</script>